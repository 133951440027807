html {
  background: $color-alabaster;  
  background-attachment: fixed;
}

html, body, #root, .App {
  @include typography-base(0);
  margin: 0;
  height: 100vh;
  text-align: center;  
}

.hide {
  display: none !important;
}

// TODO: Move dashboard-wrapper to proper scss file if needed
.dashboard-wrapper {
  display: flex;
}

// TODO: Move content-container to proper scss file if needed
.content-wrapper {
  width: 100%;
}

.remove-negative-margins {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: auto !important;
}

.compact-bottom {
  margin-bottom: 5px !important;
  padding-bottom: 5px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.show-for-large {
  display: none;
  @include breakpoint(large){
    display: inherit !important;
  }
} 

.show-for-xlarge {
  display: none;
  @include breakpoint(xlarge){
    display: inherit !important;
  }
} 