.form-section {
    & > header h3{
        margin-top: .5rem;
        margin-bottom: .875rem;
        padding-bottom: .3125rem;
        border-bottom: solid 1px transparentize($color-friar-grey, .80);
        text-transform: uppercase;
    }
}

label.form-label {
    margin-top: .125rem;
    margin-bottom: .15rem;
    font-size: 1.025rem;
    letter-spacing: .02125rem;
    font-weight: 800;
    display: block;
    line-height: 1.5;

    &.small {
        font-size: 1rem;
        font-weight: 400;
    }
    
    &.top-border {
        padding-top: 8px;
        padding-left: 1px;
        border-top: solid 1px transparentize($color-friar-grey, .80);
    }

    &.bottom-border {
        padding-bottom: 8px;
        border-bottom: solid 1px transparentize($color-friar-grey, .80);
    }
}

p.form-intro {
    margin-bottom: .5rem;
}
    
p.form-label-description {
    margin: 0;
    margin-bottom: .875rem;
    font-size: 1rem;
    letter-spacing: .02125rem;
    line-height: 1.5;
    font-weight: 400;
    display: block;
    
}

label.form-label + p.form-label-description  {
    margin-top: -.5rem;
} 
   
.helper-text {
    margin: 3px 14px .5rem;
    color: transparentize($color-text, .35);
    font-size: 0.875rem;
    text-align: left;
    font-weight: 400;
    line-height: 1.4;    
}

.required-style{
    &_radio{
        &>b{
            color:$color-text;
            font-weight: 600;
        }
    }
    color:$color-text;
    &>p,&>label{
        color:$color-text!important;
        font-weight: 600;
    }
}