.external-page-container {
  width: 100%;
  height: 100%;
  background: #f5f2f1;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  color: #102030;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: left;

  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
  }


  h1 {            
    margin: 0 0 .75rem 0;
    font-size: 1.7rem;
    font-weight: 900;
    line-height: 1.3;
    text-transform: uppercase;
    font-family: "Nunito Sans", Arial, Helvetica, sans-seri
  }
  
  p {
    font-size: 1.0625rem;
    margin-bottom: 2rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .external-content-container { 
    padding: 35px;
    width: calc(100% - 55px);
  }
  
  @media screen and (min-width: 40em) {
    .external-content-container {
        width: 70%;        
    }
  }
  
  @media screen and (min-width: 64em) {
    .external-content-container {
        width: 60%;       
    }
  }
  
  .external-content-container .content {
    margin: 0 auto;
    width: 100%;
    max-width: 550px;
    min-width: 280px;
  }
  
  .external-content-container .logo-container {
    margin-bottom: 21px;
    padding-bottom: 13px;
    border-bottom: solid 1px #00b1ad;
  }
  
  .external-content-container .logo-container .logo {
    font-size: 4rem;
    color: #00b1ad;            
    display: inline-block;
    font: normal normal normal 14px/1 AddieFkaBenchmark;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 4rem;
  }
  
  .external-content-container .logo-container .logo:before {
    content: '\f031';
  }
  
  
  .external-content-container .button.MuiButton-root {             
    width: 100%;

    @media screen and (min-width: 40em) {
      width: auto;   
    }
   
  }
  
  .external-content-container .button:hover {
    cursor: pointer;
  }
  
    
  
  .help-link-container {
    margin-top: 34px;
    width: 100%;
    padding-top: 13px;
    border-top: solid 1px #d5d5d5;
    font-size: .875rem;

    a {
      color: #102030;
      text-decoration: none;
      transition: color 210ms;

      
      @media screen and (min-width: 40em) {
        &:hover {
          color: #00b1ad;
          cursor: pointer;
        }
      }

    }

    p {
      margin-bottom: .5rem;      
    }
  }
  
  
  
  .external-supportive-container {            
    width: 20px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;                
    background: #102030 url(../../assets/images/addie-supportive-bg-img.jpg) no-repeat center bottom;
    background-size: cover;          
  }
  
  @media screen and (min-width: 40em) {
    .external-supportive-container {
        padding: 35px;
        width: 30%;                 
    }
  }
  
  @media screen and (min-width: 64em) {
    .external-supportive-container {
        width: 40%;                 
    }
  }


  
}





