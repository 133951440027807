.root{
    display: flex;
  }
  .hide{
    display: none;
  }
  
  .drawerHeader{
    /* flexShrink: 0; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
  }
  .drawerPaper{
    /* width: drawerWidth; */
    width:100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .uploadWrapper{
    display: flex;
    align-items: center;
    height: 81px;
    background: $white;
    color: black;
    padding-left: 20px;
  }
  .uploadText{
      font-size: 20px;
      font-weight: bold;
  }
  .cardWrapper{
    display: flex;
  }
  .cards{
    border: 1px solid;
    padding: 20px;
    margin: 10px;
  }
  .leftSection{
  width: 30%;
  background: $white;
  height: 100%;
  }
  .main{
  margin-top: 80px;
  text-align: left;
  padding: 14px;
  display: flex;
  width: 100%;
  } 
  .uploadText{
  font-size: 20px;
  font-weight: bold;
  }
  .drawer > div {
    width:100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .topNav{
    display: flex;
     width: 100%;
     justify-content: space-between;
     align-items: center;
  }