.breadcrumb-wrapper {
    margin-top: -13px;
    z-index: 2;
    position: relative;
}

.breadcrumb-container {
    padding-top: 13px;
    padding-bottom: 5px;

    .MuiBreadcrumbs-li > a {
        font-size: .875rem;
        text-transform: capitalize;
    }
}