.report-wrapper {
  .report_page {
    padding: 0 24px;
  }
  .report {
    &_grid {
      padding-top: 3%;
      padding-bottom: 3%;
    }

    &_reporttab {
      border: 1px solid black;
      width: 12%;
      height: 10%;
      margin: 1%;
      margin-left: 0;
      text-align: center;
      cursor: pointer;
      display: inline-block;

      &_active {
        background-color: green;
        border: 1px solid green;
        color: white;
      }
    }

    &_downloadsection {
      &_left,
      &_right {
        padding-top: 0;
        padding-bottom: 5px;
      }

      &_left {
        // padding-left: 3%;
        p {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      &_right {
        // padding-right: 2%;
        text-align: right;
        & > button {
          margin: 0 1%;
          cursor: pointer;
          padding: 3px;
        }
      }

      &_placeholder {
        padding-right: 2%;
        padding-left: 3%;
        padding-bottom: 3%;
        & > div {
          width: 100%;
          height: 500px;
          text-align: center;
          border: 1px solid black;
        }
      }
    }
  }

  .report-section {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 15px;
    background-color: #eae8e7;
    margin-top: 10px;
    .report-type-heading {
      padding-bottom: 15px;
    }

    .section-headline {
      &:before {
        display: inline-block;
        font: normal normal normal 14px/1 AddieFkaBenchmark;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f021";
        margin-right: 8px;
        color: rgba($color-friar-grey, 0.21);
      }
      padding-bottom: 0.5rem;
      margin-bottom: 5px;
      border-bottom: solid 1px rgba($color-friar-grey, 0.21);
    }

    .psuedo-button-radio-group {
      // this is an overloaded class based on reference in _form-input.scss
      justify-content: space-between;
      max-width: 1100px;
    }

    .report-dates {
      @include border-radius;
      background-color: #ffff;
      padding: 17px 20px;
      margin-bottom: 15px;

      p {
        max-width: 850px;
        z-index: 1;
        position: relative;
      }
    }
    .inner-section {
      margin-bottom: 1rem;
    }

    .alert-container {
      // this is an overloaded class based on .MuiAlert-root and reference in _alerts.scss
      margin-bottom: 10px;
    }

    .test-date-select-group {
      display: inline-block;
      margin: 0 1rem 0.5rem 1px;

      .form-label {
        // org. class for form-label found in form-typography.scss
        margin-top: 8px;
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1.25;
      }

      .drop-down-root {
        margin: 0 0 0.5rem -5px;
        .MuiSelect-root.MuiSelect-outlined.MuiOutlinedInput-input {
          padding: 12px 8px;
          margin: 0;
        }
      }
    }

    .reporting-chart-controls {
      padding-top: 0;
      padding-left: 1px;
      margin-top: 1.5rem;
      &.top-border {
        border-top: solid 1px transparentize($color-friar-grey, 0.8);
      }

      .form-label {
        margin-top: 6px;
        //margin-bottom: -6px;
        line-height: 1.1;
      }

      .link-preview-graph {
        margin-top: 3px;
        margin-bottom: -8px;
        display: block;
        font-size: 0.875rem;
        color: $color-friar-grey;
        transition: color 210ms;
        .ag-icon {
          font-size: 1.3rem;
          margin-left: 5px;
          vertical-align: text-bottom;
          transition: color 210ms;
        }

        &:hover {
          color: $color-secondary-action;
          cursor: pointer;
          .ag-icon {
            color: $color-secondary-action;
          }
        }
      }

      .drop-down-root {
        margin-bottom: 0;
      }
    } // END .reporting-chart-controls

    .reporting-accordion-charts {
      &.MuiAccordion-root {
        &:before {
          opacity: 0;
        }
        margin: 8px 0;
      }
      .reporting-accordion-charts-summary,
      .MuiAccordionSummary-content {
        display: block;
        padding: 0;
        margin: 0;

        .MuiAccordionSummary-expandIcon {
          position: absolute;
          top: -8px;
          right: 0;
        }
      }

      .reporting-accordion-details.MuiAccordionDetails-root {
        padding: 8px;
      }
    }
  } // END .report-section

  .build-report-button {
    margin-bottom: 0.75rem;
    .ag-icon {
      margin-left: 1rem;
      font-size: 1.025rem;
    }
  }
}

.hidechartfromview {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.page,
.uipage {
  font-size: 10px !important;
  font-family: "Helvetica";
  height: 1038px;
  width: 816px;
  position: relative;
  margin: 10px 0;
  padding: 0 8px;
  padding-left: 12px;
  padding-top: 1%;
  background-color: white;
}

.page .noheadings {
  color: #ffffff;
  border: none !important;
}
.page .noheadings tr th {
  color: #ffffff;
  border: none !important;
}

.page .noheadings .coloredcell {
  background: #ffffff !important;
}

.pdfwrapper {
  padding: 0.5% 1%;
  width: 98%;
  //max-height: 1065px;
  margin-bottom: 20px;
  //overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.49);
  border: solid 1px rgba($color-friar-grey, 0.1);
  @include border-radius(5);
}

.coloredcell {
  background-color: #c2ecea !important;
}

.report_table,
.uireport_table {
  font-family: Arial, Helvetica, sans-serif !important;
  border-collapse: collapse;
  width: 93%;
  font-size: 14px !important;
  text-align: right;
  table-layout: fixed;
  margin-left: 3%;

  tr {
    &:nth-child(even) {
      background: #ffff;
    }
    &:nth-child(odd) {
      background: #f5f5f5;
    }

    td {
      font-size: 12px !important;
      border: 1px solid #d0d0d0;
      text-align: right;
      padding: 0 3px;
      &:first-child {
        font-size: 12px !important;
        border-right: 4px solid #adadad;
        word-break: break-word;
        text-align: left;
        font-weight: normal;
      }
      &:nth-child(2) {
        border-left: 0;
      }
    }
    th {
      font-size: 12px !important;
      border-bottom: 4px solid #adadad;
      border-right: 1px solid #d0d0d0;
      border-top: 1px solid #d0d0d0;
      padding-right: 3px;
      font-weight: bold;
      background-color: #fff;
      // word-break: break-word;
      &:first-child {
        border-top: 0;
        max-width: 280px;
        width: 32%;
      }
      &:nth-child(2) {
        width: 20%;
      }
    }
  }
  tr.move-left-wrapper {
    td:first-child {
      border: 0;
      background: #fff;
      border-right: 4px solid #adadad;
      font-weight: bold;
      padding: 0 0px 0 24px;
      margin-right: 0;
      width: 100%;
      z-index: 9999;
      .firstlevelpadding {
        border: 1px solid #d0d0d0;
        border-right: 0;
        margin-bottom: 0;
        font-size: 10px;
        font-weight: normal;
        color: #000;
        width: auto;
        margin-right: 2px;
        padding-left: 3px;
      }
      .secondlevelpadding {
        margin-left: 24px;
        padding-left: 10px;
        border: 1px solid #d0d0d0;
        border-right: 0;
        word-break: break-word;
        width: auto;
        margin-right: 2px;
        font-weight: normal;
        font-size: 10px;
      }
    }
    &:nth-child(odd) {
      .firstlevelpadding {
        background: #f5f5f5;
      }
      .secondlevelpadding {
        background: #f5f5f5;
      }
    }
    &:nth-child(even) {
      .firstlevelpadding {
        background: white;
      }
      .secondlevelpadding {
        background: white;
      }
    }
  }
}

.reportTableinner-wrapper {
  display: flex;
  justify-content: space-between;
  div {
    width: 70px;
    margin: 0px 2px 0 0;
    padding: 0 8px 0 0;
  }
}

.reportTableinner-data:last-child {
  border-right: 0;
  padding: 0;
}

.reportTableinner-data {
  border-right: 1px solid #ddd;
  padding-right: 5px;
}

.imgWrapper {
  display: flex;
  align-items: center;
  .purniaimage {
    display: inline-block;
    width: 17em;
    margin-right: 10px;
  }
  .pdfheading {
    margin: 0;
    font-size: 2em;
    height: 1em;
  }
}

.pdfsubheading,
.uipdfsubheading {
  font-size: 14px;
  font-weight: 400;
  display: block;
  text-align: center;
  padding-top: 5px;
}

.coloredheading {
  color: #00b1ad;
  font-size: 14px;
  margin: 0 0 0 5px;
  font-weight: bolder;
}
.uicoloredheading {
  color: #00b1ad;
  font-size: 16px;
  margin: 0 0 0 5px;
  font-weight: bolder;
}

.tableSubHeading {
  font-size: 13px;
  letter-spacing: 0;
  font-weight: bold;
  line-height: 1.45;
  margin: 0 0 0 5px;
}
.uitableSubHeading {
  font-size: 14px;
  letter-spacing: 0;
  font-weight: bold;
  line-height: 1.45;
  margin: 0 0 5px 5px;
}

.tableSubHeadline,
.uitableSubHeadline {
  font-size: 12px;
  letter-spacing: 0;
  font-weight: 500;
  line-height: 1.45;
  margin: 0 0 5px 5px;
}

.pdfflag {
  width: auto;
  height: 15px;
}

.graphimage {
  width: 98%;
  margin: 0;
  padding: 0;
}

.reporthalfpage {
  display: inline-block;
  width: 44%;
  margin: 0;
  padding: 0;
  margin-left: 3%;
  height: auto;
  margin-bottom: 4%;
}

.reportfullpage {
  display: inline-block;
  width: 97%;
  margin: 0;
  padding: 0;
  margin-left: 5px;
  height: auto;
  margin-bottom: 4%;
}

.reportonethirdpage {
  display: inline-block;
  width: 30.33%;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  height: auto;
  margin-bottom: 4%;
}

.error-msg {
  color: red;
  font-weight: 600;
  margin-bottom: 0;
  text-align: left;
  font-size: 16px;
}

.invalid-msg {
  margin-top: 20px;
}

.report_table tr td.dashstyle {
  border-bottom: 2px solid black !important;
}

.pdfheading {
  font-size: 3em;
  margin: 0;
  padding: 0;
  letter-spacing: 0;
  font-weight: 800;
  text-align: center;
}

.reportFooterWrapper {
  display: flex;
  align-items: center;
  .purina_image {
    display: inline-block;
    margin-right: 15px;
    width: 90px;
  }
}

.endalign {
  text-align: end;
}

// footer css

.footer {
  position: absolute;
  bottom: 2%;
  margin-left: 3%;
  margin-right: 5%;
}

.footer_details {
  position: absolute;
  bottom: 8%;
  margin-left: 3%;
  margin-right: 5%;
  p {
    font-size: 10px;
    margin-bottom: 0 !important;
  }
  ul {
    list-style-type: disc;
    margin: 0 15px;
  }
}

.pager_footer {
  position: absolute;
  bottom: 4%;
  margin-left: 3%;
  margin-right: 5%;
  p {
    font-size: 10px;
    margin-bottom: 0 !important;
  }
  ul {
    list-style-type: disc;
    margin: 0 15px;
  }
}

.pagerfooterflag {
  width: 9px;
  height: 9px;
  margin-right: 4px;
}

.pagerfootercriticalflag {
  width: 27px;
  height: 9x;
  margin-right: 4px;
}

.uipage {
  height: 2000px;
  width: calc(98.9% - 10px);
  @include box-shadow;
}

.uireport_table {
  tr {
    td {
      font-size: 14px !important;
      &:first-child {
        font-size: 18px !important;

        & > .firstlevelpadding {
          font-size: 18px !important;
        }
        & > .secondlevelpadding {
          font-size: 14px !important;
        }
      }
    }
    th {
      font-size: 18px !important;
    }
  }
}

.uipdfsubheading {
  font-size: 24px;
}

.uicoloredheading {
  font-size: 18px;
}

.uitableSubHeading {
  font-size: 18px;
}

.uitableSubHeadline {
  font-size: 18px;
  font-weight: bold;
}

.uipdfheading {
  margin: 0;
  text-align: center;
  font-size: 5em;
  height: 1em;
  font-weight: bold;
}

.uipdfflag {
  width: auto;
  height: 18px;
}

.uipagefooterdiv {
  & > .pager_footer {
    font-size: 14px !important;
    & > p {
      font-size: 14px !important;
    }
    & > .reportFooterWrapper {
      & > p {
        font-size: 14px !important;
      }
    }
  }
}

@media (max-width: 1600px) {
  //90% and above zoom
  .uireport_table {
    tr {
      td {
        font-size: 14px !important;
        &:first-child {
          font-size: 14px !important;

          & > .firstlevelpadding {
            font-size: 14px !important;
          }
          & > .secondlevelpadding {
            font-size: 14px !important;
          }
        }
      }
      th {
        font-size: 14px !important;
      }
    }
  }

  .uipdfsubheading {
    font-size: 24px;
  }

  .uicoloredheading {
    font-size: 14px;
  }

  .uitableSubHeading {
    font-size: 14px;
  }

  .uitableSubHeadline {
    font-size: 14px;
  }

  .uipdfheading {
    margin: 0;
    text-align: center;
    font-size: 4em;
    height: 1em;
    font-weight: bold;
  }

  .uipdfflag {
    width: auto;
    height: 14px;
  }

  .uipagefooterdiv {
    & > .pager_footer {
      font-size: 14px !important;
      & > p {
        font-size: 14px !important;
      }
      & > .reportFooterWrapper {
        & > p {
          font-size: 14px !important;
        }
      }
    }
  }
}

.managecustomdiv {
  width: 90%;
  padding: 1% 2%;
  margin: 1%;
  border: 1px solid #00b1ad;
  font-weight: bold;
  border-radius: 8px 8px 8px 8px;
}

.layoutedit {
  text-align: end;
  font-size: 1rem;
  color: #00b1ad;
  cursor: pointer;
}

.layoutdelete {
  text-align: center;
  font-size: 1rem;
  color: #808078;
  cursor: pointer;
}

.twodialogcancel {
  background: #808078 !important;
}

.cullinghistory_header {
  color: #00b1ad;
  font-weight: bold;
}

.no_border {
  background-color: #ffffff !important;
  border: none !important;
}

.chart-actions {
  padding: 0 !important;
}

.drag_drop_info {
  float: right;
}

.flex_div {
  width: 100%;
  display: flex;
}

.item_right {
  display: flex;
  gap: 15px;
  margin-left: auto;
}

.chartmodal_title {
  p {
    font-size: 14px;
    margin-bottom: 1rem;
    margin-left: 2px;
  }
}

.chart_modal_action {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.downloadChart-cont {
  width: 100%;
  text-align: center;

  .button {
    margin: 0 auto;
    margin-left: 45px;
  }
}
.centered {
  width: 60%;
  margin: 0 auto;
}
