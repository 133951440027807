.paper-root-table-wrapper.MuiPaper-root {
    @include box-shadow;
    .table-wrapper {
        @include border-radius(8, 8, 0, 0);
    }
    &.MuiPaper-rounded {
        @include border-radius;
    }
}
.table-wrapper.MuiTableContainer-root  {
    .table-root {
        .table-thead {
            .table-tr {
                .table-th {

                }
            } // END .table-tr
        } // END .table-thead 

        .table-tbody {
            .table-tr {
                .table-td {
                    font-weight: 600;
                    font-size: 1rem;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-transform: capitalize;
                    border-bottom: solid 1px rgba($color-primary-bg, .05);

                    &.td-cta {
                        text-align: right;

                        .button {
                            margin-top: .25rem;
                            margin-bottom: .5rem;
                            width: 100%;

                            @include breakpoint(xlarge){
                                width: auto !important;
                              }
                        }
                    }
                } // END .table-td

                &:nth-child(even) {
                    background-color: rgba($color-primary-bg, .025);
                }
            } // END .table-tr
        } // END .table-tbody

    } // END .table-root

    .MuiTableCell-root {        
        border-right: 1px solid rgba($color-ecru-white, .25);
    }
} // END .table-wrapper