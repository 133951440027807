h1, h2, h3, h4, h5, p {
  @include typography-base;
  letter-spacing: .02125rem;
  font-weight: 800;
}
h1 {
  font-size:2.1rem;
  font-weight: 900;
}

h1.appbar-headline {
  font-size:1.7rem;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1.3;
}
h1.appbar-altheadline {
  font-size: 1.025rem;
  font-weight: 400;
  line-height: 1;
}

h2 {
  font-size: 1.515rem;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: .5rem;
}

h4 {
  font-size: 1.05rem;
  margin-bottom: .5rem;
}

.section-headline,
.card-section-headline {
  text-transform: uppercase;
  margin-bottom: .875rem;
}

p {
  font-size: 1.025rem;
  margin-bottom: 2rem;
  letter-spacing: 0;
  font-weight: 500;
  line-height: 1.45;
}

a {
  color: red;
  text-decoration: none;
}

.text-uppercase {
  text-transform: uppercase;
}

