.container_div{
    // padding: 20px 40px 40px;
    &_image {
      display: inline-block;
      vertical-align: top;
      margin-right: 3%;
      border-radius: 50%;
      width:50px;
      height:50px;
    }
    &_text {
      display: inline-block;
      & > span{
        color:#808078;
        opacity:67%;
      }
    }
  }

  .available_user_wrapper{
    max-height: calc( 100vh - 400px);
    overflow-y: auto;
    height: auto;
  }