/*--------- Removing Increment & Decrement Icon from Input Field-----*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.goals-wrapper {
    .goals-accordion-wrapper {
        &.MuiPaper-root {
            width: 100%;
            background: none;
            
            &.MuiAccordion-root {
                margin-bottom: 0;
                border-top: solid 1px rgba($color-primary-bg, .1);
                
                &:before {
                    height: 0;
                    background-color: none;
                }

                .goals-accordion-summary.MuiAccordionSummary-root {
                    padding: 0px 3px;
                    min-height: auto;
                    background: none;
        
                    .grab-dots {
                        color: $color-text;
                        margin: 8px 13px 0 0;
                        opacity: .21;
                    }
        
                    .MuiAccordionSummary-content {
                        margin: 9px 0 8px;
        
                        .goals-accordion-header {
                            color: $color-text;
                            transition: color 210ms, margin 210ms;
                        }
        
                        &:hover {
                            .goals-accordion-header { 
                                margin-left: 2px;
                                color: $color-primary-action; }                            
                        }
                    } // .MuiAccordionSummary-content
                } //.goals-accordion-summary
                
                
                &.Mui-expanded {
                    margin: 0;
                    .goals-accordion-summary.MuiAccordionSummary-root {
                        .MuiAccordionSummary-content {
                            .goals-accordion-header {
                                //color: $color-secondary-action;                        
                                color: $color-primary-action;
                            }
                        }                        
                    }                    
                }
            } // .MuiAccordion-root         
        } // .MuiPaper-root        
    } // .goals-accordion-wrapper

    // TABLE (base CSS .table-wrapper is found in _tables.scss
    .goals-table.table-wrapper {
        @include border-radius(8, 8, 8, 8);
        .edit-icon {
            color: rgba($color-ecru-white, .55);
            border-radius: 50%;
            border: 1px solid;
            padding: 6px;            
            font-size: .75rem;
            border: solid 1px rgba($color-ecru-white, .55);
            transition: color 210ms, border 210ms;
            &:hover {
                cursor: pointer;
                color: $color-primary-action;
                border: solid 1px rgba($color-primary-action, 1);
            }
            &.active {
                background: #0da2a0;
                border: 1px solid #0da2a0;
            }
        }
        .table-root {
            margin-bottom: 20px;
        }
        .table-th {
            .edit-icon {  
                margin-top: -3px;
                float: right;                
            }
        }
        td {
            .edit-icon {
                color: $color-primary-bg;
                padding: 7px;
                width: auto;
                min-width: auto;
                opacity: .75;
                .ag-icon {
                    font-size: .875rem;
                }
            }
            &.table-td:last-child {
                text-align: center;
            }
        }
        .icon-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .button.MuiButton-root.MuiButton-sizeSmall {
                margin: 0 2px;
                padding: 4px 0;
                width: auto;
                min-width: 30px;
                .ag-icon {
                    font-size: 1.2rem;
                }
                
            }
            
        }
        .MuiInputBase-input {
            padding-left: 5px;
            margin-top: -13px;
        }
        .default-section {
            .MuiInputBase-input.Mui-disabled {
                pointer-events: none;
            }
        }
    } // .table-wrapper   
}// .goals-wrapper

.tank-average-section {
    label {
        color: #000;
    }
    .MuiInput-root {
        width: 100%;
        margin-bottom: 7px;
        min-width: 240px;
        .MuiSelect-selectMenu{
            padding: 13px 14px;
            border: 1px solid #d4d4d4 ;
            border-radius: 4px;
        }
    }
}
.error {
    color: red;
}
.submit-wrapper {
    display: flex;
    align-items: center;
}
#popper {
    width: 220px;
    top: 5px !important;
    z-index: 10;
    @include box-shadow(0, 0, 34, .13);
    .MuiPaper-root {
        padding: 15px;
        text-align: left;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }
    .popper-fields {
        margin-top: 10px;
    }
    &:before {
        content: "\f020";
        display: inline-block;
        font: normal normal normal 14px/1 DigitalAgPlatform;
        font-style: normal;
        font-variant-ligatures: normal;
        font-variant-caps: normal;
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-weight: normal;
        font-stretch: normal;
        font-size: inherit;
        line-height: 1;
        font-family: DigitalAgPlatform;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        left: 40%;
        width: 0;
        height: 0;
        font-size: 5rem;
        color: #fff;
        opacity: 0;
        transition: bottom 210ms;
        top: -45px;
        opacity: 1;
    }
}

/* User Goal Styles */

.goal-value-wrapper {
    padding: 24px 24px 12px;
    background: #f2f3f4;
    @include border-radius(0, 0, 5, 5);

    .import-section {
        @include border-radius(5, 5, 5, 5);  
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba($color-secondary-action, .08);
        padding: 15px 24px;
        margin: 24px 0 12px;
        font-size: .9rem;
        &.imported {
            background: #ffe8d9;
        }
    }
    .input-fields {
        align-items: inherit;
        .textfield-label {
            font-weight: bold;
        }
    }
    .MuiOutlinedInput-root {
        background: white;
    }
}

.predefined-wrapper {
    display: flex;
    align-items: center;
    .MuiSelect-select {
        padding-right: 24px;
        padding: 10px;
        font-weight: bold;
        border: 1px solid #9f9e9e;
    }
    & > div:nth-child(2) {
        margin: 15px 0 0 15px;
    }
}

/* Modal */

.goal-modal-wrapper {
    background-color: rgba(209, 204, 189, 0.34) !important;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100px;
    left: 270px;
    right: 0;
    bottom: 0;
    z-index: 99;
    .text-field-root {
        margin: 0px 0 25px 0;
    }
    .MuiSelect-selectMenu {
        border: 1px solid #8d8d8d;
        padding: 10px;
    }
    .import-modal {
        background: white;
        border-radius: 10px;
        text-align: left;
        padding: 20px 20px 30px 20px;
        max-width: 300px;
    }
    .modal-title , .modal-buttons {
        display: flex;
        justify-content: space-between;
    }
    .modal-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        font-weight: 800;
    }
    .close-icon {
        cursor: pointer;
    }
}

button:disabled {
    opacity: 0.7;
}

.ml-2 {
    margin-left: 15px;
}

.expand-link {
    color: #00b1ad;
    cursor: pointer;
}

.goals-list-text {
    font-size: 1.025rem;
    margin-bottom: 0;
    letter-spacing: 0;
    font-weight: 600;
}

.MuiAlert-outlinedWarning {
    border: none !important;
}

.tooltip-goal {
    font-size: 13px !important;
    background-color: #00b1ad !important;
    font-weight: bold !important;
    text-align: left !important;
}

.tooltip-arrow {    
    color: #00b1ad !important;
}

.subtext-teal {
    color: #00b1ad;
    font-size: 12px !important;
    font-weight: normal !important;
}

.header-subtext-teal {
    color: #00b1ad;
    font-size: 14px !important;
    font-weight: bold !important;
}