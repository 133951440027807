.upload-section {
    .no_herd_msg {
        padding-left: 10px;
    }
    .upload-file-card {
        margin-top: .5rem;
        margin-bottom: 1.5rem;
        font-size: 1.025rem;
        letter-spacing: .02125rem;
        font-weight: 800;
        display: block;
        line-height: 1.5;

        .upload-file {
            padding-top: 10px;
            .upload-heading {
                font-weight: bold;
                text-align: left;
            }
            .browse-btn {
                @include border-radius;
                padding: 10px;
                border: 1px dashed transparentize($color-friar-grey, .50);
                background-color: $color-white;                
                height: 57px;
                display: flex;
                margin: 10px 0 5px;
                align-items: center;
                padding-left: 10px;
                position: relative;
                
                label {
                    background: $color-alabaster;
                    margin-right: 10px;
                    position: absolute;
                    left: 5px;
                    width: 75px;
                    top: 5px;
                    display: flex;
                    border-radius: 4px;
                    padding: 10px;
                    justify-content: center;
                    height: 61%;
                    transition: background 210ms;
                    
                    i {
                        color: $color-secondary-action;
                        font-size: 31px;
                        position: absolute;
                        top: 15%;
                        left: 25%;
                        border: 1px solid transparentize($color-friar-grey, .80);
                        border-radius: 50%;
                        padding: 7px;
                        transition: color 210ms;

                    }
                    &:hover {
                        background: $color-white;
                        cursor: pointer;
                        i {
                            color: $color-primary;
                        }
                    }
                }
                input[type="file"] {
                    margin-top: 20px;
                    
                }
                input[type="file"]:focus {
                    outline: 0px;
                }
                span {
                    position: absolute;
                    left: 100px;
                    top: 15px;
                }
            }
        }
    }
    .error-msg {
        color: red;
        font-weight: 600;
        margin-bottom: 0;
        text-align: left;
        font-size: 14px;
    }    
    .invalid-msg {
        margin-top: -40px;
    }   
}

.upload_success_container {
    Button {
        margin: 0 5px;
    }
}

.centeredaction{
    justify-content: center !important;
}
