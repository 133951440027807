.MuiContainer-root {
    &.align-left,
    &.container-left-align {
        margin-left: 0;
        margin-right: 0;
    }
}

.content-container {
    padding-top: 34px;
    padding-bottom: 55px;
    width: 100%;
    text-align: left;
    .MuiTableContainer-root{
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .MuiTableCell-stickyHeader {
            background-color: #102030 !important;
            h4 {
                font-size: 14px;
                text-transform: uppercase;
                color: #fff;
                margin-bottom: 0;
            }
        }
    }
}
