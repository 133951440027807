// Add/Edit Subherd 
.enrollment-form-manage-sub-herd-list-row{
    margin: 0 8px !important;
    align-items: center;
    border-bottom: solid 1px transparentize($color-friar-grey, .80);
    max-width: 800px;
    &:first-child {
        margin-top: 1rem !important;
        border-top: solid 1px transparentize($color-friar-grey, .80);
    }    

    &:nth-child(even) {
        background-color: transparentize($color-primary-bg, .95);
    }

    & > div.MuiGrid-item,
    .sub-herd-list-name.MuiGrid-item,
    .sub-herd-list-action.MuiGrid-item {
        padding-bottom: 8px;
        margin: 0 !important;
    }

    .sub-herd-list-name {
        span {
            white-space: nowrap;
            text-overflow: ellipsis;
        }

    }
    .sub-herd-list-action {
        text-align: right;
    }
}

.enrollment-form-manage-sub-herd-list-row + .MuiGrid-root > .button.add-edit-subherd-button {
    margin-top: 1rem;
}
.button.add-edit-subherd-button {
    margin-bottom: 1rem;
}


// Add He       rd Page scss
.addHerd_form{
    text-align: left;

 width:100%;
}


// Add Herd Success Page scss
.add_herd_success{
    &_container{
        padding-top: 15vh;
        text-align: center;
    }

    &_description,&_redirection{
        margin:10px 0;
    }

}

.disabled_button{
    background:$color-friar-grey !important;
    cursor: not-allowed !important;
    color: white !important;
}
