.graphWrapper {
    padding: 0;
    .select-graph {
        margin-bottom: 20px;
    }

    .removeChart {
        background-color: #fff;
        border: 0;
        color: #979696;
        margin-top: 12px;
        cursor: pointer;
        &:focus {
            outline: 0;
        }
        .MuiSvgIcon-root {
            border: 1px solid;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            margin-right: 5px;
            position: relative;
            top: 3px;

        }
    }

    .addChartBtn {
        margin-top: 30px;
        padding: 6px 18px 8px 18px;
        border-radius: 50px;
        background-color: #eee;
        color: #00b1ad;
        font-weight: 600;
        font-size: 13px;
        border: 0;
        text-transform: uppercase;
        cursor: pointer;
        &:focus {
            outline: 0;
        }
        &:hover {
            background-color: #e5e5e5;
        }
        .MuiSvgIcon-root {
            width: 15px;
            height: 15px;
            position: relative;
            top: 2px;
            right: 2px;
        }
    }
    
    .sortChartBtn {
        margin-top: 30px;
        padding: 6px 18px 8px 18px;
        border-radius: 50px;
        background-color: #eee;
        color: #00b1ad;
        font-weight: 600;
        font-size: 13px;
        border: 0;
        text-align: right;
        text-transform: uppercase;
        cursor: pointer;
        &:focus {
            outline: 0;
        }
        &:hover {
            background-color: #e5e5e5;
        }
        .MuiSvgIcon-root {
            width: 15px;
            height: 15px;
            position: relative;
            top: 2px;
            right: 2px;
        }
    }
}

.savesort-btn {
    margin-top: 20px;
    text-align: center;
}

.icon-right {
    float: right;

    .modal-switch {
        margin-top: -15px;
    }
}


.apexcharts-legend-text {
    font-size: 15px !important;
}

