.leftmodalStyle{
    height:99vh;
    margin:0;
    padding:0;
    text-align: left;

    &_width{
        width:40vw;
        margin-left:60vw;
    }

    &_largewidth{
        width:60vw;
    margin-left:40vw;
    }
 
    &_div{
     background-color: white;
     padding-left:5%!important;
     height:100%;
     color:black;
     padding:10px;
     border:none;
     outline: 0;
    }
}

.text_left{
    text-align: left;
}

.dropdownStyle{
    border-radius: 10px !important;
    background-color: white;
    margin-top: 10px;
    min-width: 300px !important;
    overflow: visible !important;
    &:before {
        // content: "\f020";
        // display: inline-block;
        // font: normal normal normal 14px/1 DigitalAgPlatform;
        // font-style: normal;
        // font-variant-ligatures: normal;
        // font-variant-caps: normal;
        // font-variant-numeric: normal;
        // font-variant-east-asian: normal;
        // font-weight: normal;
        // font-stretch: normal;
        // font-size: inherit;
        // line-height: 1;
        // font-family: DigitalAgPlatform;
        // font-size: inherit;
        // text-rendering: auto;
        // -webkit-font-smoothing: antialiased;
        // position: absolute;
        // right: 48px;
        // width: 0;
        // height: 0;
        // font-size: 5rem;
        // color: #fff;
        // opacity: 0;
        // transition: bottom 210ms;
        // top: -40px;
        // opacity: 1;

        content: '';
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 25px 25px 0px 25px;
        border-color: transparent white transparent transparent;  
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        top: -3px;
        left: 0;
        right: -260px;
        margin: 0 auto;
        transform: rotate(225deg);
        opacity: 1;
    }
    li {
        &:not(:last-child) {
            border-bottom: 1px solid #d7d7d7;
        }
        &:hover,&.Mui-selected,&.Mui-selected:hover {
            background: #e1f5f4;
            width:93%;
        }
    }
}
.align-end {
    float: inline-end;
    margin-top: -5px;
}