.MuiInputBase-input {
    @include text-inputs();  
    // TODO: look to move to same class near line 16              
}

// Text Fields
.MuiInputBase-formControl,
.MuiTextField-root.text-field-root,
.MuiOutlinedInput-root.text-field-root,
// Drop Down Menus
.MuiFormControl-root.drop-down-root,
.drop-down-root,
.MuiAutocomplete-root.autocomplete-root,
.autocomplete-root{
    width: 100%; 
    margin-bottom: .5rem;
    .MuiFormLabel-root {        
        &.Mui-focused {
            color: $color-primary;
        }
    }

    .MuiInputBase-root {
        
    }

    .MuiInputBase-input.MuiOutlinedInput-input {
        padding: 13.5px 14px;        
    }

    // Used in placeholder text
    .MuiInputLabel-formControl {
        top: -5px;
    }
    // Used in placeholder text when shrinks
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, 0px) scale(0.75);
    }
    // used as border when outlined variant is used
    .MuiOutlinedInput-notchedOutline {
        transition: border 510ms ;
        border-bottom: solid 2px rgba(0, 0, 0, 0.23) ;
    }

    .MuiOutlinedInput-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline{
        border-color: $color-primary;
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {        
        border: solid 1px $color-primary;
        border-bottom-width: 2px;
    }
}

.textarea {
    width: 92%; 
    margin-bottom: .5rem;
    @include typography-base;
    padding: .8rem;
    font-size: 1.025rem;
}

.psuedo-button-radio-group {
    margin-bottom: 0;
    display: flex;
    .psudeo-input-item {
        @include border-radius;
        @include box-shadow;
        width: 134px;
        height: 74px;
        background: #fff;
        display: inline-block;
        margin: 0 1.25rem .875rem 0;
        color: $color-primary;
        font-weight: 800;
        line-height: 1.1;
        text-align: center;
        padding: 0 5px 2px;
        display: grid;
        align-content: center;
        text-transform: uppercase;
        transition: background 210ms, box-shadow 210ms;

        &:hover {        
            @include box-shadow(0,0,35,.21);  
            //background: transparentize($color-primary, .85);
            cursor: pointer;
        }

        &.active {        
            @include box-shadow(0,0,0,0);  
            background: $color-primary; 
            color: #fff;  
            position: relative;                 
        }
        &:before {            
            // content: '\f020';
            // content: "•";
            // display: inline-block;
            // // font: normal normal normal 14px/1 DigitalAgPlatform;
            // font-style: normal;
            // font-variant-ligatures: normal;
            // font-variant-caps: normal;
            // font-variant-numeric: normal;
            // font-variant-east-asian: normal;
            // font-weight: normal;
            // font-stretch: normal;
            // font-size: inherit;
            // line-height: 1;
            // font-family: DigitalAgPlatform;
            // font-size: inherit;
            // text-rendering: auto;
            // -webkit-font-smoothing: antialiased;
            // bottom: 10px;
            // position: absolute;
            // left: 34%;
            // width: 0;
            // height: 0;
            // font-size: 5rem;
            // color: #fff;  
            // opacity: 0;
            // transition: bottom 210ms, opacity;   
            
            content: '';
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 32px 0px 0px 32px;
            // border-color: transparent #dd4397 transparent transparent;  
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            bottom: -13px;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(225deg);
            opacity: 0;
            @include border-radius(4);
        }     

    }

    &.add-carrot {
        .psudeo-input-item.active:before {                        
            bottom: -32px;
            opacity: 1;                    
        } 
    }
}

// Radio & Toggle Switches
.MuiGrid-root.radio-group,
.MuiGrid-root.switch-button-group {
    //margin-bottom: 1rem;
    margin-bottom: .5rem;
    & > b,
    & > fieldset > legend {
            margin-bottom: -25px;
            font-size: 1.025rem;
            letter-spacing: .02125rem;
            font-weight: 800;
            display: block;
            line-height: 1.5;
            padding-top: 8px;
            //border-top: solid 1px transparentize($color-friar-grey, .80);        
            color: $color-text
    }
    & > fieldset > legend {
        margin-bottom: 10px;
    }
}


// Radio Specific
.MuiFormControlLabel-root {
    .MuiRadio-colorPrimary.Mui-checked {
        color: $color-primary;
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: $color-primary;
    }
}

// Multiselect Menu
.MuiMenuItem-root {
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: $color-primary;
    }
}


// Toggle Switch Specific
.MuiSwitch-root {
    .MuiSwitch-colorSecondary.Mui-checked {
        color: $color-primary;
        
        &:hover {
            background-color: rgba($color-tangaroa, .05);
        }
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: desaturate($color-primary, 80%);
    }

    &.text-bold + .MuiFormControlLabel-label {
            font-weight: 700;
       
    }
}

.non_rquired_goal_switch {
    .MuiSwitch-colorSecondary.Mui-checked {
        color: $color-primary-non_rquired_goal_switch;
        
        &:hover {
            background-color: rgba(gray, .05);
        }
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: desaturate(gray, 80%);
    }

    &.text-bold + .MuiFormControlLabel-label {
            font-weight: 700;
       
    }
}

.notallowed_cursor{
    cursor:not-allowed;
    caret-color: $color-primary!important;
}


.toggle-control-input-wrapper {
    align-items: center;
    > div:nth-child(1){
        border-right: solid 1px transparentize($color-friar-grey, .80);
    }
    > div:nth-child(2){
        padding-left: 25px;        
    }
}

// Autocomplete 
.MuiAutocomplete-root.autocomplete-root {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding:0;
        padding-right: 0 !important;
        background-color: #fff; 
        .MuiAutocomplete-input {
            padding: 13.5px 14px;
        }      
    }
    .MuiInputLabel-formControl {
        top: -5px;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, 1px) scale(.75)
    }
    
}

// Multi-Select
.multiselect-root,
.MuiFormControl-root.multiselect-root {
    width: 100%;

    label + .MuiInput-formControl {
        margin-top: 0;
    }
    .MuiSelect-select {
        @include text-inputs();
        padding-right: 0;
        padding: 5px;
        border: solid 1px rgba(0, 0, 0, 0.23);
        border-bottom: solid 2px rgba(0, 0, 0, 0.23) ;
        min-height: 35px;
        height: auto;        
    }

    .Mui-disabled {
        background: #10203008 !important;
    }

    .MuiInputBase-root:before,
    .MuiInputBase-root:hover:before,
    .MuiInputBase-root:after,
    .MuiInputBase-root:hover:after {
        border: none;
    }
    // Icon on right side
    .ag-icon.ag-icon-list-ul.MuiSvgIcon-root.MuiSelect-icon {
        color: $color-primary;
        font-size: 1.21rem;
        top: calc(50% - 9px);
        right: 12px;
    }
}
