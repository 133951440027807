// @import "../../scss/main.scss";


// TODO: move colors & fonts to variables
// TODO: move button CSS to buttons

.login-container {
    width: 100%;
    height: 100vh;
    background-color: #102030;
    
    text-align: center;
    margin: 0 auto;
  
    .login-form-container {
      width: 100%;
      height: 90vh;
      background: #102030 url(../../assets/images/login-bg-02b.png) no-repeat center bottom;
      background-size: cover;
    }
  
    .login-form {
      margin: 0 auto;
      padding-top: 25vh;
      .logo {
        font-size: 6rem;
        color: #fff;
        margin: 20px 0 40px 1.6rem;
        display: block;
        &.ag-icon-benchmark-logo-local, 
          &.ag-icon-benchmark-logo-dev, 
          &.ag-icon-benchmark-logo-qa {
              font-size: 8rem;
              margin-left: 2rem;
          }
      }
    }
  
    .ag-icon-inc-logo {
      margin-left: -112px;
      color: #808078;
      font-size: 1.5rem;
      position: absolute;
      bottom: 30px;
      left: 50%;
    }  
  }
  
  // BUTTONS
  .login-form-container .MuiButton-root {
    padding: 10px 32px;
    font-size: 1rem;
    border-radius: 100px;
  }
  
  
  .login-form-container .MuiButton-contained {
    background: #e5a62b !important;
    color: #fff;
    font-weight: 800;
  }
  
  .login-form-container .MuiButton-contained:hover {
    background: #E87722;
  }
  
  
  .login-form-container .hide {
    display: none !important;
  }
  
  
  
  .login-form-container .MuiCircularProgress-root {
            margin-left: 20px;
            svg {
              color: $white;
            }
          }
          
          .login-form-container .alert-error {
          margin-top: 10px;
        }