div[class^="loader"] {
    svg {
        
        color: $color-secondary-action;
    }
}

.loader-sm {
    @include blur-component-bg;
    position: relative;
    display: flex;
}

.input-loader {
    //background-color: #fff;    
    margin-top: 0;
    position: absolute;
    z-index: 1;
    right: 8px;
    top: 12px;
    svg {
        
        color: $color-secondary-action;
    }
}

.loader-lg {
    @include blur-component-bg;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 92px;
    left: 255px;
    right: 0;
    bottom: 0;
    z-index: 100;
}