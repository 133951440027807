.button.MuiButton-root {
    border-radius: 50px;
    padding: 10px 30px;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    text-align: center;

    .MuiButton-label {
        font-weight: 900;
        font-size: .8125rem;
        letter-spacing: .5px;
    }

    &.MuiButton-contained {
        background: $color-primary;
        .MuiButton-label {
            color: #fff;
        }  
        
        &:hover {
            background: darken($color-primary, 5%);
        }

        &.grey {
            background: rgba($color-text, .13);
            
            .MuiButton-label {
                color: $color-primary;
                transition: color 210ms;
            }

            &:hover {
                background: darken($color-primary, 5%);
                
                .MuiButton-label {
                    color: #fff;
                    
                }
            }
        }

        
    }

    &.MuiButton-containedSecondary {
        background: $primary;
        .MuiButton-label {
            color: #fff;
        }  
        
        &:hover {
            background: darken($primary, 5%);
        }
    }

    &.MuiButton-outlined {
        background: #fff;
        border: 1px solid $color-primary;
        .MuiButton-label {
            color: $color-primary;
        }  
        
        // &:hover {
        //     background: darken($color-primary, 5%);
        // }
        &.grey {
            border-color: $color-friar-grey;
            .MuiButton-label {
                color: $color-friar-grey;
            } 
        }
    }

    &.MuiButton-sizeLarge {
        padding: 10px 40px;
        .MuiButton-label {            
            font-size: 1.25rem;
        }

    }

    &.MuiButton-sizeSmall {
        padding: 6px 18px 4px;
        .MuiButton-label {            
            font-size: .875rem;
        }
        .MuiButton-startIcon.MuiButton-iconSizeSmall {
            margin-top: -2px;
            svg {
                font-size: 1.25rem;
            }
            
        }

    }

    &.size-mini {
        //padding: 5px ​15px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        .MuiButton-label {            
            font-size: .8rem;
        }
    }
}

// Wrapping container for buttons at the bottom of a form/page
.button-submit-container,
.button-submit-container.MuiGrid-spacing-xs-2 {
    margin: 13px auto 55px;
    padding-top: 21px;
    border-top: solid 1px transparentize($color-friar-grey, .80);  
    width: auto;
    .button-submit-container-left {
        text-align: left;
    }
    .button-submit-container-right {
        text-align: right;
    }
    .button-submit-container-center {
        text-align: center;
    }

    &.no-border {
        border-top: none;
    }
}