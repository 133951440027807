.navigation-drawer-container {
    //background: $color-tangaroa;
    height: 100vh;
}

.navigation-app-info {
    background: url(../../assets/images/navigation-app-info-bg.png) no-repeat center bottom;
    background-size: 100%;
    min-height: 172px;
    padding: 25px 24px 40px;
    margin-bottom: 25px;

    .logo {
        font-size: 3.75rem;
        color: #fff;
        text-align: left;
        margin-bottom: .65rem;
        &.ag-icon-benchmark-logo-local, 
        &.ag-icon-benchmark-logo-dev, 
        &.ag-icon-benchmark-logo-qa {
            font-size: 5rem;
            margin-bottom: -10px;
            margin-top: -2px;
        }
        

    }
}

.navigation-list-container {
    .nav-list-item {
        transition: background-color 210ms ease-out;
        .MuiListItemText-root,  
        .MuiTypography-body1{
            color: $color-alabaster;
            letter-spacing: .03125rem;  
            text-transform: uppercase;
            font-weight: 700;          
        }

        .MuiListItemIcon-root .ag-icon {
            margin-left: 10px;
            font-size: 1.6rem;
            color: $color-alabaster;

            &.ag-icon-cow {
                font-size: 1.8rem;
            }
            &.ag-icon-add {
                font-size: 1.35rem;
                margin-left: 11px;
            }
        }

        &:hover {
            background-color: rgba(160,160,160, .13);
            background-color: -moz-linear-gradient(90deg, rgba(160,160,160,0.05) 0%, rgba(160,160,160,0.13) 100%);
            background-color: -webkit-linear-gradient(90deg, rgba(160,160,160,0.05) 0%, rgba(160,160,160,0.13) 100%);
            background-color: linear-gradient(90deg, rgba(160,160,160,0.05) 0%, rgba(160,160,160,0.13) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a0a0a0",endColorstr="#a0a0a0",GradientType=1);
        }

        &.active {
            background: rgba(160,160,160, .08);
            background: -moz-linear-gradient(90deg, rgba(160,160,160,0.03) 0%, rgba(160,160,160,0.08) 100%);
            background: -webkit-linear-gradient(90deg, rgba(160,160,160,0.03) 0%, rgba(160,160,160,0.08) 100%);
            background: linear-gradient(90deg, rgba(160,160,160,0.03) 0%, rgba(160,160,160,0.08) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a0a0a0",endColorstr="#a0a0a0",GradientType=1);
        }
    }


    
}

.navigation-drawer {
    .privacy-policy {
        position: relative;        
        margin-top: 100%;
        margin-bottom: 20px;
        margin-left: -70px;
        a {
            color: #fff;
        }
    }
}