.two-button-dialog {
    .MuiDialog-paper {
        min-width: 360px;
    }
    .dialog-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 15px;
        button {
            padding: 0;
        }
    }
    .action-btns {
        padding: 0 15px 15px;
        button{
            color: $white;
            font-size: 12px;
            font-weight: bold;
            padding: 5px;
            &:first-child {
                background: grey; 
            }
            &:last-child {
                background: $primary; 
            }
        }
    }
}