.subherd-details-overview-container {
    @include border-radius;
    border: solid 1px transparentize($color-tangaroa, .90); 
    padding: 13px 8px;
    h5 {}
    p {
        margin-bottom: 0;
        text-transform: capitalize;
    }

    > div {
        padding: 0 0 0 20px;
        border-right: solid 1px transparentize($color-tangaroa, .90); 

        &:first-child {
            padding-left: 13px;
        }

        &:last-child {
            border-right: none;
        }
    }
}

.replace-upload-wrapper {
    .buttonWrapper {
        display: flex;
        justify-content: space-between;
    }

    .fileListStyling {
        border: 2px solid #00b1ad;
        padding: 5px 10px;
        border-radius: 7px;
        margin-bottom: 15px;
        margin-top: 10px
    }    
}

.uploadFilesWrapper {
    margin-top: 15px;
    button {
        margin-top: 20px;
    }
    .error-msg {
        color: red;
        font-weight: 600;
        margin-bottom: 0;
        text-align: left;
        font-size: 14px;
    }
}

.upload-file {
    .upload-heading {
        font-weight: bold;
        text-align: left;
    }
    .browseBtn {
        @include border-radius;
        padding: 15px 0;
        border: 1px dashed $border-color-1;
        border-radius: 4px;
        height: 40px;
        display: flex;
        margin: 10px 0 5px;
        align-items: center;
        padding-left: 10px;
        position: relative;
        label {
            background: $color-alabaster;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            width: 75px;
            top: 5px;
            display: flex;
            border-radius: 4px;
            padding: 10px;
            justify-content: center;
            height: 61%;
            transition: background 210ms;
            i {
                color: $color-secondary-action;
                font-size: 31px;
                position: absolute;
                top: 15%;
                left: 25%;
                border: 1px solid transparentize($color-friar-grey, .80);
                border-radius: 50%;
                padding: 7px;
                transition: color 210ms;
            }
            &:hover {
                background: $color-white;
                cursor: pointer;
                i {
                    color: $color-primary;
                }
            }
        }
        input[type="file"] {
            margin-top: 20px;
            
        }
        input[type="file"]:focus {
            outline: 0px;
        }
        span {
            position: absolute;
            left: 100px;
            top: 15px;
        }
       
    }
}

.boundary {
    margin-top: 10px;
    background-color: #102030;
    height: 40px;
}

.uploaded_by {        
    color: #000;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    text-decoration: none;   

    &:hover {
        text-decoration: underline;
        &::after {
            text-decoration: underline;
        }
    }
  }    
