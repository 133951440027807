.fullwidthbutton{
    width:100%;
}

.resetbutton{
    background: #fff !important;
    border: 1px solid #00b1ad !important;
    width: 100%;

    &>span{
        color: #00b1ad !important;
    }
}

.herd_list{
    &_view_button{
        cursor: pointer;
        padding:5px;
        margin-left:2px;
    }
    &_search{
        text-align: left;
        margin-top: 10px!important;
        &_button{
            cursor: pointer;
            padding:18px;
        }
    }

    &_subherd_div{
        border:1px solid black;
        margin:5px 0;
        text-align: center;
        cursor: pointer;
    }
   
}

.herd_auto{
    display: inline-block;
}

.herdname-column {
    word-break: break-all;
}

.addherd_container_input{
        width:60%;
        padding:5px;
        margin-right:3%!important;
    &2{
        width:30%;
        padding:5px;
        margin-right:3%!important;
    }
    
    &3{
        width:15%;
        padding:5px;
        margin-right:3%!important;
    }
    
    &4{
        width:10%;
        padding:5px;
        margin-right:3%!important;
    }
    
    &5{
        width:8%;
        padding:5px;
        margin-right:3%!important;
    }

}

.subherd-wrapper {
    max-height: calc( 100vh - 340px);
    margin-bottom: 20px;
    overflow-y: auto;
    height: auto;
    .subherd-list {
        display: flex;
        border: 1px solid #00b1ad;
        margin: 15px 0;
        padding: 10px 15px;
        border-radius: 8px;
        text-align: left;
        min-height: 50px;
        cursor: pointer;
        align-items: center;
        div[class^="subherd-"] {
            width: 12px;
            height: 12px;
            margin-right: 15px;
            border-radius: 50%;
        }
        .subherd-active{
            background: green;
        }
        .subherd-inactive{
            background: #c7c2c2;
        }
    }
}

.alert-error {
    color: red;
}

.btn-wrapper {
    button:first-child {
        margin-right: 10px;
    }
}

.extra-action {
    position: absolute;
    right: 0;
}