.form-section {
    margin-bottom: 2.5rem;

    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
        padding-bottom: 2px;
    }
}

// This is add'l class where .page-control is the base class. 
.page-controls.form-page-controls {
    padding: 12px 24px 10px;
    margin: 0 12px 34px;
    width: 100%;
}