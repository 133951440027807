// COLORS
$color-carrot-orange: #e87722;
$color-gold-tips: #e5a62b;
$color-persian-green: #00b1ad;
$color-shadow-green: #97c0bb;
$color-ecru-white: #d1ccbd;
$color-astronaut-blue: #204f56;
$color-friar-grey: #808078;
$color-metal-grey: #38383b;
$color-tangaroa: #102030;
$color-carmine: #b00020;
$color-alabaster: #F5F2F1;
$color-white: #fff;
$color-primary-non_rquired_goal_switch:gray;

$color-primary: $color-persian-green;
$color-primary-action: $color-persian-green;
$color-secondary-action: $color-carrot-orange;
$color-text: $color-tangaroa;
$color-primary-bg: $color-tangaroa;



// OLD COLORS 
// TODO: Remap these variables & colors
$primary:#cc262c;
$white: #ffff;
$modal-bg: rgba(0, 0, 0, 0.5);
$box-shadow-1: 0 6px 12px 0 rgba(0,0,0,0.2);
$hover-btn-bg: #aa2025;
$bg-color-1: #f7f7f7;
$border-color-1: #d7d7d7;

// TYPOGRAPHY
$base-font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
$letter-spacing: .03125rem;